<template>
  <div v-intersect="onIntersect" class="d-flex flex-nowrap qtm-body text-dark-grey comment-row">
    <div class="flex-grow-1">
      <div class="d-flex justify-space-between">
        <div class="qtm-label">
          {{ comment.created_by.first_name }} {{ comment.created_by.last_name }}
        </div>
        <div class="qtm-overline text-mid-dark-grey" v-text="dateTime(comment.date_created)" />
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="comment-text" v-html="comment.text" />
      <div v-if="comment.attachments.length" class="d-flex mt-3">
        <attachment-preview-download
          v-for="attachment in comment.attachments"
          :key="attachment.id"
          :attachment="attachment"
          class="mr-3"
          :download-only="downloadOnly"
          @click="$emit('preview', attachment)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { dateTime } from '~/models/filters'
import AttachmentPreviewDownload from '@/components/attachments/attachment-preview-download.vue'

export interface Props {
  comment: any
  downloadOnly?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['preview', 'update:comment'])

const { $api } = useNuxtApp()

const onIntersect = (isIntersecting: boolean) => {
  if (isIntersecting) {
    props.comment.pending_read_receipts.forEach((receipt: any) => {
      $api.v1.comments.readReceipts.markAsRead(receipt.id).catch(console.error)
    })
    emit('update:comment', { ...props.comment, pending_read_receipts: [] })
  }
}
</script>

<style scoped>
.comment-row:hover {
  background-color: rgb(var(--v-theme-background));
}
span.comment-text {
  white-space: pre-line;
}
</style>
